import 'regenerator-runtime/runtime'
import html2canvas from 'html2canvas'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import Sockette from 'sockette'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { messageTypesIn, messageTypesOut, wait } from 'shared'
import fontBold from './MountainsofChristmas-Bold.ttf'
import font from './MountainsofChristmas-Regular.ttf'
import waitingAnimation from './waiting.gif'
import waiting2Animation from './waiting2.gif'
import loadingAnimation from './loading.gif'
import presentAnimation from './present.gif'
import cookieAnimation from './cookie.gif'

const waitingTime = 6000

const Wrapper = styled.div`
  background-color: #034f20;
  display: flex;
  justify-content: center;
  color: white;
  padding: 20px;

  @font-face {
    font-family: 'christmas';
    src: url('${font}');
  }

  @font-face {
    font-family: 'christmas';
    src: url('${fontBold}');
    font-weight: bold;
  }

  font-family: christmas;
  font-size: 22px;
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  h1 {
    text-align: center;
  }

  button {
    background-color: #BB2528;
    border-color: #BB2528;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 25px;
  }

  .loading {
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    overflow: hidden;
  }

  .waitingAnimation {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .waitingAnimation2 {
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .result {
    background: white;
    padding: 40px;
    color: #034f20;
  }

  a {
    background-color: #BB2528;
    border-color: #BB2528;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
  }
`

const stages = {
  loading: 'loading',
  name: 'name',
  waiting: 'waiting',
  youDraw: 'youDraw',
  othersDraw: 'othersDraw',
  end: 'end',
}

const ProgressBar = styled.div`
  background: orange;
  border-radius: 2px;
  height: 30px;
  transition: width ${waitingTime}ms ease-out;
  width: ${ifProp('load', '100%', 0)};
`

const preloadImage = (url) =>
{
  const img = new Image()
  img.src = url
}

const App = () => {
  const ws = useRef()
  const [stage, setStage] = useState(stages.loading)
  const [onlineUsers, setOnlineUsers] = useState([])
  const [availableUsers, setAvailableUsers] = useState([])
  const [userId, setUserId] = useState(null)
  const [userDraw, setUserDraw] = useState(null)
  const [showResult, setShowResult] = useState(false)
  const [othersDrawName, setOthersDrawName] = useState()
  const [showDownload, setShowDownload] = useState(false)
  const [image, setImage] = useState()

  useEffect(() => {
    preloadImage(waitingAnimation)
    preloadImage(waiting2Animation)
    preloadImage(loadingAnimation)
    preloadImage(presentAnimation)
    preloadImage(cookieAnimation)
  }, [])

  useEffect(() => {
    ws.current = new Sockette('ws://77.220.111.202:3000', {
      timeout: 5000,
      maxAttempts: 10,
      onmessage: ({ data }) => {
        const { message, ...rest } = JSON.parse(data)
    
        if (message === messageTypesOut.userUpdate) {
          const { waitingUsers, onlineUsers } = rest
          setAvailableUsers(waitingUsers)
          setOnlineUsers(onlineUsers)
          if (userId) {
            setStage(stages.waiting)
          } else {
            setStage(stages.name)
          }
        } else if (message === messageTypesOut.starting) {
          setStage(stages.loading)
        } else if (message === messageTypesOut.draw) {
          const { userId: serverUserId, userDraw, name: othersDrawName } = rest
          if (serverUserId === userId) {
            setUserDraw(userDraw)
            setStage(stages.youDraw)
          } else {
            setOthersDrawName(othersDrawName)
            setStage(stages.othersDraw)
          }
        } else if (message === messageTypesOut.end) {
          setStage(stages.end)
        }
      },
    })
  }, [userId])

  useEffect(async () => {
    if (stage === stages.end) {
      setImage(await html2canvas(document.body))
      setShowDownload(true)
    }
  }, [stage])

  const registerAs = useCallback((newUserId) => {
    setStage(stages.loading)
    setUserId(newUserId)
    ws.current.json({
      message: messageTypesIn.register,
      userId: newUserId,
    })
  }, [])

  const openResult = useCallback(async (key) => {
    setShowResult(true)
    await wait(waitingTime)
    setShowResult(false)
    ws.current.json({
      message: messageTypesIn.draw,
      userId,
    })
  })

  const renderStage = (s) => {
    switch(s) {
      case stages.loading:
        return (
          <>
            <div className="loading">
              <img src={loadingAnimation} />
            </div>
            <h1>
              Loading
            </h1>
          </>
        )
      case stages.name:
        return (
          <>
            <h1>
              Hallo, wer bist du?
            </h1>
            <img className="waitingAnimation" src={waitingAnimation} />
            {availableUsers.map((user, key) =>
              <button key={`registerButton${key}`} onClick={() => registerAs(user.userId)} value={user.userId}>{user.name}</button>
            )}
          </>
        )
      case stages.waiting:
        return (
          <>
            <h2>Wir sind bereit!</h2>
            <ul>
              {onlineUsers.map((user, key) =>
                <li key={`onlineUsersWaiting${key}`}>{user.name}</li>
              )}
            </ul>
            <img className="waitingAnimation2" src={cookieAnimation} />
            <h2>Wir sind noch offline!</h2>
            <ul>
              {availableUsers.map((user, key) =>
                <li key={`availableUsersWaiting${key}`}>{user.name}</li>
              )}
            </ul>
          </>
        )
      case stages.youDraw:
        return (
          <>
            <h1>Du bist dran!</h1>
            {!showResult &&
            <>
              <h3>Bitte ziehe einen Zettel:</h3>
              <button onClick={openResult}>
                Ziehen
              </button>
            </>
            }
            {showResult &&
              <>
                <div>
                  Bitte schenke etwas an:
                </div>
                <h1 className="result">{userDraw.name}</h1>
                <img src={presentAnimation} className="waitingAnimation2" />
                <div>
                  10 Sekunden bis zur nächsten Ziehung, du kannst das Ergebnis am Ende nochmal sehen.
                </div>
              </>
            }
            <ProgressBar load={showResult} />
          </>
        )
      case stages.othersDraw:
        return (
          <>
            <h1>{othersDrawName} ist dran.</h1>
            <img src={waiting2Animation} className="waitingAnimation2" />
          </>
        )
      case stages.end:
        return (
          <>
            <h1>Alle haben gezogen.</h1>

            <div>Nochmal zum merken. Du schenkst etwas an:</div>

            <h1 className="result">
              {userDraw.name}
            </h1>

            {showDownload &&
              <a href={image.toDataURL()} download="engerl-bengerl.png">
                Als Bild speichern
              </a>
            }


            <img src={presentAnimation} className="waitingAnimation2" />
          </>
        )
    }
  }

  return (
    <Wrapper>
      <Page>
        {renderStage(stage)}
      </Page>
    </Wrapper>
  )
}

export default App